
import { motion, useSpring, useTransform, useInView, AnimatePresence, cubicBezier, useAnimate, stagger } from 'framer-motion';
import { useRef, useState, useEffect, useContext } from 'react';
import { useInViewScroll, MotionImage } from 'ui';
import { useRouter } from 'next/router';
import Link from 'next/link';

const calculateTimeLeft = (targetDate) => {
  const difference = new Date(targetDate) - new Date();

  let timeLeft = {
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  };

  if (difference > 0) {
    timeLeft = {
      days: Math.floor(difference / (1000 * 60 * 60 * 24)),
      hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
      minutes: Math.floor((difference / 1000 / 60) % 60),
      seconds: Math.floor((difference / 1000) % 60),
    };
  }

  return timeLeft;
};

const useCountdown = (targetDate) => {
  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft(targetDate));

  useEffect(() => {
    const intervalId = setInterval(() => {
      setTimeLeft(calculateTimeLeft(targetDate));
    }, 1000);

    return () => clearInterval(intervalId);
  }, [targetDate]);

  return [timeLeft.days, timeLeft.hours, timeLeft.minutes, timeLeft.seconds]
    .map(val => String(val).padStart(2,'0'));
};

const Digit = ({value}) => {
  
  return <span className="relative border h-full border-[#fff6] text-white w-5 lg:w-6 rounded-md overflow-clip">
    <AnimatePresence>
      <motion.span 
        key={value}
        initial={{opacity: 0, y: -10}}
        animate={{opacity: 1, y: 0}}
        exit={{opacity: 0, y: 10}}
        transition={{ease: 'circInOut'}}
        className="inline-block absolute w-full h-full px-[2px] flex items-center justify-center lg:px-1">
        {value}
      </motion.span>
    </AnimatePresence>
    
  </span>
}

const date = new Date('11-01-2024')
export const Banner = () => {
    const { ref, progress: amt } = useInViewScroll([0, 1], ['start 100vh', 'end 0vh']);
    const scrollY = useTransform(amt, [0, 1], [0, 50]);


    const [slide, setSlide] = useState(0);
    // const intervalId = useRef(-1)

    // useEffect(() => {
    //   intervalId.current = setInterval(() => {
    //     setSlide(slide => (slide+1)%2);
    //   }, 10000);
  
    //   return () => clearInterval(intervalId.current);
    // }, [slide]);

    // const goto = (num) => {
    //   clearInterval(intervalId.current)
    //   setSlide(num)
    // }
  
    const inView = useInView(ref, { once: true, margin: '-30% 0% -30% 0%'});

    const [ days, hours, minutes, seconds ] = useCountdown(date)
    
    const backgrounds = [
      'linear-gradient(to bottom, #0166fe, #013d7c)',
      'linear-gradient(to top, rgb(20, 13, 19), rgb(91, 46, 79))'
    ]

    const pathname = useRouter().asPath;

    const isTv = pathname.indexOf('products/xumo-tv') >= 0

    const ctaUrl = isTv 
      ? "https://www.xumo.com/offer/nba?intcmp=ILC:XTVMP:bufl:0:INB:NBA:offer:910003"
      : "https://www.xumo.com/offer/nba?intcmp=ILC:XSBMP:bufl:0:INB:NBA:offer:910004"

    const product = isTv 
      ? "Xumo TV" 
      : "Xumo Stream Box"
  
    return <motion.section 
      initial={{opacity: 0, y: 40}}
      animate={{opacity: inView ? 1 : 0, y: inView ? 0 : 40}}
      transition={{ease: [0,1,1,1]}}
      ref={ref} className="relative z-40 bg-white w-full" data-testid={'text-section'}>
      <div className="wrapper my-2">
        
        <Link 
          href={ctaUrl} 
          aria-labelledby="banner-cta-label" 
          className={`relative block rounded-3xl cursor-pointer group outline outline-2 outline-transparent outline-offset-2 hover:outline-xumoTeal transition-colors`} 
          style={{background: backgrounds[slide] }}>
          {/* container */}
          <motion.div 
            initial={{
              x: 0,
              opacity: 1
            }}
            animate={{
              x: slide === 0 ? 0 : -10,
              opacity: slide === 0 ? 1 : 0,
              zIndex: slide === 0 ? 5 : 0
            }}
            transition={{ease:'cubicInOut'}}
            className="flex flex-col md:flex-row">
            <div className="p-6 lg:p-8 xl:p-12 pr-6 md:min-w-[12rem] xl:min-w-auto flex shrink-0 text-white ">
              <div className="relative h-full w-auto  min-h-[50px] md:min-h-[60px] max-md:max-h-[60px] xl:min-h-[80px] aspect-[2400/1560]">
                <MotionImage
                    src="/static/images/refresh/nba-league-pass-dark.png"
                    priority
                    fill
                    sizes="100vw"
                    initial={{opacity: 0}}
                    animate={{opacity: inView ? 1 : 0}}
                    transition={{delay: 0}}
                    className="object-fit" />
              </div>
            </div>
            <motion.div 
              initial={{opacity: 0}}
              animate={{opacity: inView ? 1 : 0}}
              transition={{delay: 0.2}}
              className="max-md:content-wrapper pt-0 md:pr-6 md:py-4 flex flex-col min-w-[0px] min-h-full items-start justify-center text-white type-body">
              <span className="block text-lg lg:text-xl xl:text-2xl mb-1">Your home for streaming hoops</span>
              <span id="banner-cta-label" className="text-sm lg:text-base">Stream live game action from your {product} with NBA League Pass&nbsp;for&nbsp;$16.99/month.<br/><span className="block mt-2 underline decoration-2 decoration-[#fff6] underline-offset-4 group-hover:decoration-[#fffc] transition-colors">Start&nbsp;your&nbsp;7&#8209;day&nbsp;free&nbsp;trial&nbsp;today</span></span>
              
              <div className="mt-2 text-xs text-left justify-start text-[#fff7] weight-300">
                Taxes and fees extra. Blackout and other restrictions apply.
              </div>
            </motion.div>
            <div className="hidden flex lg:min-w-[38%] min-h-full relative">
              <div className="aspect-[2] w-full absolute bottom-0 right-0 overflow-clip rounded-3xl pointer-events-none">
                <div className="group-hover:scale-[1.02] transition-all relative w-full h-full will-change-transform">
                  <MotionImage
                    style={{y:scrollY}}
                    initial={{opacity: 0}}
                    animate={{opacity: inView ? 1 : 0}}
                    transition={{delay: 0.4}}
                    src="/static/images/refresh/nba-takeover.png"
                    priority
                    fill
                    sizes="100vw"
                    className="object-fit" />
                </div>
              </div>
            </div>
          </motion.div>
          {/*container*/}

          {/* container */}
          {/* <motion.div 
            initial={{
              x: 0,
              opacity: 1
            }}
            animate={{
              x: slide === 1 ? 0 : -10,
              opacity: slide === 1 ? 1 : 0,
              zIndex: slide === 1 ? 5 : 0
            }}
            className="absolute flex top-0 left-0 w-full h-full">
            <div className="p-6 lg:p-8 xl:p-16 xl:pr-8 flex text-white">
              <div className="relative h-full min-h-[60px] text-lg xl:text-xl weight-600 gap-2 flex">
                <div className="text-center flex flex-col items-center">
                  <div className="flex grow gap-[2px] justify-center items-center">
                    <Digit value={days[0]} />
                    <Digit value={days[1]} />
                  </div>
                  <div className="flex shrink text-[#fff6] text-xs weight-300">D</div>
                </div>
                <div className="text-center flex flex-col items-center">
                  <div className="flex grow gap-[2px] justify-center items-center">
                    <Digit value={hours[0]} />
                    <Digit value={hours[1]} />
                  </div>
                  <div className="flex shrink text-[#fff6] text-xs weight-300">H</div>
                </div>
                <div className="text-center flex flex-col items-center">
                  <div className="flex grow gap-[2px] justify-center items-center">
                    <Digit value={minutes[0]} />
                    <Digit value={minutes[1]} />
                  </div>
                  <div className="flex shrink text-[#fff6] text-xs weight-300">M</div>
                </div>
                <div className="text-center flex flex-col items-center">
                  <div className="flex grow gap-[2px] justify-center items-center">
                    <Digit value={seconds[0]} />
                    <Digit value={seconds[1]} />
                  </div>
                  <div className="flex shrink text-[#fff6] text-xs weight-300">S</div>
                </div>
              </div>
            </div>
            <motion.div 
              initial={{opacity: 0}}
              animate={{opacity: inView ? 1 : 0}}
              transition={{delay: 0.2}}
              className="pr-4 py-4 flex grow min-h-full items-center text-white type-body">
              <span>Don’t miss these Black Friday offers! <span className="underline decoration-2 decoration-[#fff6] underline-offset-4 group-hover:decoration-[#fffc] transition-colors">Shop&nbsp;deals</span></span>
            </motion.div>
            <div className="flex md:min-w-[40%] min-h-full relative">
              <div className="aspect-[2] w-full absolute bottom-0 right-0 overflow-clip rounded-3xl pointer-events-none">
                <div className="group-hover:scale-[1.02] transition-all relative w-full h-full">
                  <MotionImage
                    style={{y:scrollY}}
                    initial={{opacity: 0}}
                    animate={{opacity: inView ? 1 : 0}}
                    transition={{delay: 0.4}}
                    src="/static/images/refresh/black-friday-devices.png"
                    priority
                    fill
                    sizes="100vw"
                    className="object-fit" />
                </div>
              </div>
            </div>
          </motion.div> */}
          {/*container*/}
        </Link>
      </div>
    </motion.section>
  }