/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable max-len */
import { motion, useSpring, useTransform, useInView, AnimatePresence } from 'framer-motion';

import { useRef, useState, useEffect } from 'react';
import { MotionImage, useSanitizedTranslation, XumoButton as Button, StackedContent} from 'ui';
import Link from 'next/link'


const TileSection = ({subheading, tiles}) => {
  const { t } = useSanitizedTranslation();

  const transition = { ease: "easeInOut", duration: 0.3, restDelta: 0, restSpeed: 0 }
  const variants = { 
    image: { hover: { opacity: 0, scale: 1.04 } },
    hoverImage: { hover:  { opacity: 1, scale: 1.04 } }
  }

  const wrapperStyles = tiles.length === 4
    ? 'lg:w-2/3'
    : ''

  return <>
    <div className="type-subheading-small text-center mt-8" dangerouslySetInnerHTML={{ __html: subheading }} />
    <div className={"flex gap-4 my-8 relative flex-wrap w-full justify-center " + wrapperStyles}>
      {tiles.map(tile => 
        <motion.div 
          key={tile.image.src} 
          style={{ aspectRatio: 1145/600 }}
          whileHover="hover" 
          className="relative cursor-pointer flex basis-[calc(50%-16px)] lg:basis-[calc(33.33%-16px)]">
            <Link href={tile.href} aria-label={tile.label} target="_blank" className="relative block w-full h-full shadow-sm hover:shadow-xl rounded-md outline outline-2 outline-offset-2 outline-transparent hover:outline-xumoTeal overflow-clip" 
              style={{ 
                aspectRatio: 1145/600,
                transition: 'all 0.3s'
               }}>
              <MotionImage
                src={tile.image.src}
                alt=""
                initial={{opacity: 1, scale: 1}}
                transition={transition}
                variants={variants.image}
                priority
                fill
                sizes="100vw"
                className="object-cover w-full h-full" />
              <MotionImage
                alt=""
                src={tile.hover_image.src}
                initial={{opacity: 0, scale: 1}}
                transition={transition}
                variants={variants.hoverImage}
                fill
                sizes="100vw"
                className="object-cover w-full h-full" />
            </Link>
        </motion.div>
    )}
    </div>
    </>
}

export const CtaTileBlock = ({heading, body, sections, buttons}) => {
    const { t } = useSanitizedTranslation();

  const bg = ''
  const content = 'content'

    return <motion.section className="relative z-40 bg-white w-full flex justify-center" data-testid={'text-section'}>
      <div className="wrapper">
        <div className={`content-wrapper-x flex flex-col items-center justify-center ${bg} py-16`}>
          <StackedContent
            center
            noOrphans
            content={[
              { heading },
              { body }
            ]} />
          
          {sections.map(section => <TileSection {...section} />)}
          
          { 
          // TODO: ButtonRow 
          }
          <div className="flex justify-center mt-4">
            {buttons.map(button => <Button {...button} />)}
          </div>
         
        </div>
      </div>
    </motion.section>
  }